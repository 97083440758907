define('jZoom', ['app', 'siteObj', 'jThumbs', 'jThumbsM'], (app, siteObj, jThumbs, jThumbsM) => {
  
  class jZoom {
    constructor() {
      const init = this.buildContainer();
      this.addEvents = this.addEvents.bind(this);
      this.jZoom = init.container;
      this.image = init.image;
      this.overlay = init.overlay;
      this.controls = this.buildControls(this.jZoom);
      this.dragImage = this.dragImage.bind(this);
      this.toggle = this.toggle.bind(this);
      this.applyToggle = this.applyToggle.bind(this);
      this.disableDrag = this.disableDrag.bind(this);
      this.pinchZoom = this.pinchZoom.bind(this);
      this.addEvents();
    }

    addEvents() {
      this.image.addEventListener('touchstart', e => this.touchHandler(e));
      this.image.addEventListener('touchend', e => this.touchEndHandler(e));
      this.image.addEventListener('touchcancel', e => this.touchEndHandler(e));
      this.image.addEventListener('mousedown', e => this.enableDrag(e));
      this.image.addEventListener('mouseup', () => this.disableDrag());
      this.image.addEventListener('mouseleave', e => this.disableDrag(e, 'leave'));

      this.jZoom.addEventListener('click', e => e.stopPropagation());
      this.overlay.addEventListener('click', this.toggle);
      this.overlay.addEventListener('mouseup', this.applyToggle);

      window.addEventListener('resize', () => {
        this.resize();
        this.orient();
        this.checkM();
      });
    }

    /**
     * Build and append image container to the DOM.
     * @returns {{
     *    overlay: Element,
     *    container: Element,
     *    image: Element
     * }}
     */
    buildContainer() {
      const overlay = document.createElement('div');
      const container = document.createElement('div');
      const image = document.createElement('img');

      overlay.className = 'jZoom_overlay';
      overlay.dataset.zoomVisible = false;
      overlay.style.width = `${window.innerWidth}px`;
      overlay.style.height = `${window.innerHeight}px`;
      overlay.style.position = 'fixed';
      overlay.style.top = '0px';
      overlay.style.left = '0px';

      if (!siteObj.siteIsMobile) {
        overlay.style.background = 'rgba(0,0,0,0.7)';
      }

      container.className = 'jZoom';

      this.isPortrait = window.innerHeight > window.innerWidth;

      if (this.isPortrait) {
        image.className = 'jZoom_image-portrait';
        container.style.width = `${window.innerWidth}px`;
        overlay.style.width = '100%';
        overlay.style.height = '100%';
      } else {
        image.className = 'jZoom_image-landscape';
        container.style.height = `${window.innerHeight}px`;
      }

      image.dataset.jZoomMain = true;
      image.dataset.zoomLevel = 0;
      image.coordX = 0;
      image.coordY = 0;
      image.style.height = '100%';
      image.style.transition = 'transform 0.5s ease';
      image.style.WebkitTransition = 'transform 0.5s ease';

      container.appendChild(image);
      overlay.appendChild(container);
      document.body.appendChild(overlay);

      return {
        overlay,
        container,
        image
      };
    }

    /**
     * Build jZoom controls.
     * @param container
     * @returns {{
     *    wrapper: Element,
     *    open: Element,
     *    close: Element,
     *    zoomIn: Element,
     *    zoomOut: Element
     * }}
     */
    buildControls(container) {
      const zoomIn = this.buildButton('zoomIn', this.zoom, 'in');
      const zoomOut = this.buildButton('zoomOut', this.zoom, 'out');
      const close = this.buildButton('close', this.toggle);
      this.buildButton('open-panel', this.toggle);
      const openIcon = document.querySelector('.jZoom_open-icon' /*'.jZoom_button-wrap'*/ ); // todo
      const wrapper = document.createElement('div');

      wrapper.className = 'jZoom_controls';

      if (!this.isPortrait) {
        wrapper.style.marginLeft = `${this.getStyle(container, 'width').split('p')[0] / 2 - 108}px`;
      }

      if (!siteObj.siteIsMobile) {
        wrapper.appendChild(zoomOut);
        wrapper.appendChild(zoomIn);
        container.appendChild(wrapper);
      } else {
        container.appendChild(zoomOut);
        container.appendChild(zoomIn);
      }

      container.appendChild(close);

      return {
        wrapper,
        close,
        zoomIn,
        zoomOut,
        openIcon
      };
    }

    /**
     * Build and add events to all jZoom buttons.
     * @param type
     * @param callback
     * @param [cbArgs]
     * @returns {Element}
     */
    buildButton(type, callback, cbArgs) {
      const cb = callback.bind(this);
      const mobile = siteObj.siteIsMobile ? '-mobile' : '';
      let button = document.createElement('button');

      switch (type) {
        case 'open-panel':
          if (siteObj.siteIsMobile) {
            button = document.querySelector('.jZoom_button-open'); //todo this is failing for some reason
          } else {
            button = document.querySelector('.jZoom_open');
          }
          break;

        case 'close':
          button.className = `jZoom_button-close${mobile}`;
          break;

        case 'zoomIn':
          button.className = `jZoom_button-zoom${mobile} icon-zoomIn`;
          break;

        case 'zoomOut':
          button.className = `jZoom_button-zoom${mobile} icon-zoomOut disabled`;
          break;
      }

      button.addEventListener('click', e => cb(e, cbArgs));

      return button;
    }

    /**
     * Re-apply toggle functionality to overlay click.
     */
    applyToggle() {
      this.overlay.addEventListener('click', this.disableDrag);
    }

    /**
     * Toggle jZoom open/closed.
     */
    toggle(e) {
      app.publish('tracking/record', 'j-zoom', 'toggle');
      e = e || window.event;

      if (e) {
        e.preventDefault();
        e.cancelBubble = true;
      }

      this.orient();

      const diff = (1600 - window.innerHeight) / 2;
      const diffPercent = (diff / window.innerHeight) * 100;

      // Reset image dimensions, zoom and coordinates
      this.zoomDec = parseFloat(diffPercent) / 50;
      this.zoomPercentVal = this.zoomDec / 125;
      this.image.dataset.zoomLevel = 0;
      this.image.coordX = 0;
      this.image.coordY = 0;

      this.resize();

      if (this.overlay.dataset.zoomVisible === 'true') {
        this.overlay.dataset.zoomVisible = 'false';

        app.element.addClass('disabled', this.controls.zoomOut);
        app.element.removeClass('disabled', this.controls.zoomIn);

        document.body.style.overflow = 'scroll'; // overflow: scroll !important; in takeover CSS
      } else {
        this.buildFlashOverlay();
        this.checkAndHide();
      }
    }

    checkAndHide() {
      const hidden = document.querySelectorAll('.jZoom_overlay-superhide');

      if (!hidden) {
        document.body.style.overflow = 'hidden';
      }
    }

    /**
     * Build the flash overlay.
     */
    buildFlashOverlay() {
      const hidden = document.querySelector('.jZoom_overlay-superhide');

      if (hidden) {
        return;
      }

      const overlay = document.createElement('div');
      overlay.className = 'jZoom_overlay';
      overlay.style.height = `${window.innerHeight}px`;
      overlay.style.width = `${window.innerWidth}px`;
      overlay.style.backgroundColor = '#fff';
      overlay.style.position = 'absolute';
      overlay.style.left = '0';
      overlay.style.top = '0';
      overlay.style.zIndex = '9999';

      this.flashIn(overlay);
    }

    /**
     * Briefly display flash overlay, remove from the DOM, and prep main image position before display.
     * @param element
     */
    flashIn(element) {
      element.style.opacity = 1;
      this.overlay.dataset.zoomVisible = 'true';
      document.body.appendChild(element);

      this.recentre();

      const total = 75;
      let i = total;

      const flash = () => {
        element.style.opacity = i / total;
        i--;

        if (0 > i) {
          document.body.removeChild(element);

          this.image.style.transition = 'transform 0.5s ease';
          this.image.style.webkitTransition = 'transform 0.5s ease';
          app.element.addClass('jZoom_transition-main', this.image);
        } else {
          setTimeout(flash, 5);
        }
      };
      flash();
    }

    /**
     * Check if we need to disable either zoom buttons.
     * @param zoomLevel
     */
    zoomDisabled(zoomLevel) {
      if (0 >= zoomLevel) {
        app.element.removeClass('disabled', this.controls.zoomIn);
        app.element.addClass('disabled', this.controls.zoomOut);
      } else if (zoomLevel >= 125) {
        app.element.removeClass('disabled', this.controls.zoomOut);
        app.element.addClass('disabled', this.controls.zoomIn);
      } else {
        app.element.removeClass('disabled', this.controls.zoomIn);
        app.element.removeClass('disabled', this.controls.zoomOut);
      }
    }

    /**
     * Zoom the image in or out.
     * @param e
     * @param direction
     */
    zoom(e, direction) {
      e = e || window.event;
      e.cancelBubble = true;

      let zoomLevel = parseInt(this.image.dataset.zoomLevel);

      if (direction === 'in') {
        zoomLevel = 125;
      } else {
        zoomLevel = 0;
      }

      this.zoomDisabled(zoomLevel);

      // In case we need to make adjustments if image edges break boundaries
      this.adjustEdges(direction);
      this.scale = 1 + (zoomLevel * this.zoomPercentVal);

      /*
      todo - this is for scaleAtPoint fn
      const x = Math.abs(this.image.offsetLeft) + parseInt(this.getStyle(this.image, 'width').split('p')[0]);
      const y = Math.abs(this.image.offsetTop) + parseInt(this.getStyle(this.image, 'height').split('p')[0]);
      const nx = x * this.scale;
      const ny = y * this.scale;
      const fx = nx - x;
      const fy = ny - y;
      */

      this.image.style.transformOrigin = `${window.innerWidth / 2}px ${window.innerHeight / 2}px 0`;
      this.image.style.webkitTransformOrigin = `${window.innerWidth / 2}px ${window.innerHeight / 2}px 0`;
      this.image.style.transform = `matrix(${this.scale},0,0,${this.scale},${this.image.coordX},${this.image.coordY})`;
      this.image.style.webkitTransform = `matrix(${this.scale},0,0,${this.scale},${this.image.coordX},${this.image.coordY})`;
      this.image.dataset.zoomLevel = zoomLevel;
      this.image.style.transformOrigin = '';
      this.image.style.webkitTransformOrigin = '';


      if (zoomLevel <= 0) {
        this.recentre();
      }
    }

    adjustEdges(direction) {
      if (direction === 'in') {
        return;
      }

      const bcr = this.image.getBoundingClientRect();
      const adjustTop = (bcr.top - (bcr.top - this.image.coordY)) / 2;
      const adjustRight = (bcr.right - (bcr.right - this.image.coordX)) / 2;
      const adjustBot = (bcr.bottom - (bcr.bottom - this.image.coordY)) / 2;
      const adjustLeft = (bcr.left - (bcr.left - this.image.coordX)) / 2;

      if (0 < adjustTop) {
        this.image.coordY = adjustTop;
      }

      if (0 > adjustRight) {
        this.image.coordX = adjustRight;
      }

      if (0 > adjustBot) {
        this.image.coordY = adjustBot;
      }

      if (0 < adjustLeft) {
        this.image.coordX = adjustLeft;
      }
    }

    /**
     * Centre the image.
     */
    recentre() {
      this.image.coordX = 0;
      this.image.coordY = 0;

      if (siteObj.siteIsMobile || this.isPortrait) {
        const bodyWidth = this.getStyle(document.body, 'width').split('p')[0];
        const width = this.getStyle(this.image, 'width').split('p')[0];
        const mWidth = 0 - Math.abs((width / 2) - (bodyWidth / 2));

        this.image.style.transform = `matrix(1,0,0,1,${mWidth},0)`;
        this.image.style.webkitTransform = `matrix(1,0,0,1,${mWidth},0)`;
        this.image.coordX = mWidth;
      } else {
        this.image.style.transform = 'matrix(1,0,0,1,0,0)';
        this.image.style.webkitTransform = 'matrix(1,0,0,1,0,0)';
      }
    }

    /**
     * Drag the image to a new position.
     * @param e
     * @returns {boolean}
     */
    dragImage(e) {
      e = e || window.event;

      const transformProperty = this.getStyle(this.image, 'transform') || this.getStyle(this.image, '-webkit-transform');
      const transform = transformProperty.split('(')[1].split(')')[0].split(',');
      const lastX = parseInt(transform[4]);
      const lastY = parseInt(transform[5]);
      const X = ((e.pageX || e.touches[0].clientX) - this.offsetX);
      const Y = ((e.pageY || e.touches[0].clientY) - this.offsetY);
      const br = this.image.getBoundingClientRect();
      const newX = this.boundaryX(br, lastX, X);
      const newY = this.boundaryY(br, lastY, Y);

      this.image.style.transform = `matrix(${this.scale},0,0,${this.scale},${newX},${newY})`;
      this.image.style.webkitTransform = `matrix(${this.scale},0,0,${this.scale},${newX},${newY})`;
      this.image.coordX = newX;
      this.image.coordY = newY;
      this.offsetX = e.pageX || e.touches[0].clientX;
      this.offsetY = e.pageY || e.touches[0].clientY;

      return false;
    }

    /**
     * Calculate the X boundary based on the element offset and the previous and current X coordinates
     * @param bcr
     * @param lastX
     * @param X
     * @returns {*}
     */
    boundaryX(bcr, lastX, X) {
      const qWidth = siteObj.siteIsMobile ? (this.getStyle(this.image, 'width').split('p')[0] / 4) : 0; // m-boundary
      const offsetLeft = this.findTotalOffset(this.jZoom, 'left');
      const offsetRight = this.findTotalOffset(this.jZoom, 'right');
      const zoomLevel = this.image.dataset.zoomLevel;

      if (zoomLevel < 1 && !qWidth) {
        return lastX + X;
      } else if ((bcr.left - offsetLeft >= 0 && lastX + X > lastX) || (bcr.right - offsetRight <= 0) && lastX + X < lastX) {
        return lastX;
      } else {
        return lastX + X;
      }
    }

    /**
     * Calculate the Y boundary based on the element offset and the previous and current Y coordinates
     * @param bcr
     * @param lastY
     * @param Y
     * @returns {*}
     */
    boundaryY(bcr, lastY, Y) {
      const offsetTop = this.findTotalOffset(this.jZoom, 'top');
      const offsetBottom = this.findTotalOffset(this.jZoom, 'bottom');
      const zoomLevel = this.image.style.zoomLevel;

      if (zoomLevel < 1 && siteObj.siteIsMobile) {
        return lastY + Y;
      } else if ((bcr.top - offsetTop >= -3 && lastY + Y > lastY) || (bcr.bottom - offsetBottom <= 3 && lastY + Y < lastY)) {
        return lastY;
      } else {
        return lastY + Y;
      }
    }

    /**
     * Slide the image back within boundaries if it moves beyond them.
     */
    slideBack() {
      let transform = this.getStyle(this.image, 'transform');

      if (transform === 'none' || !transform) {
        return;
      }

      const imgBr = this.image.getBoundingClientRect();
      const containerBr = this.jZoom.getBoundingClientRect();
      const zoomLevel = this.image.dataset.zoomLevel;

      transform = transform.split('(')[1].split(')')[0].split(',');

      let X = parseInt(transform[4]);
      let Y = parseInt(transform[5]);

      if (imgBr.top > containerBr.top) {
        Y = Y - (imgBr.top - containerBr.top);
      }

      if (imgBr.right < (containerBr.right)) {
        X = X - (imgBr.right - containerBr.right);
      }

      if ((imgBr.left) > containerBr.left) {
        X = X - (imgBr.left - containerBr.left);
      }

      if (imgBr.bottom < containerBr.bottom) {
        Y = Y - (imgBr.bottom - containerBr.bottom);
      }

      if (zoomLevel > 0) {
        this.image.style.transform = `matrix(${this.scale},0,0,${this.scale},${X},${Y})`;
        this.image.style.webkitTransform = `matrix(${this.scale},0,0,${this.scale},${X},${Y})`;
      }
    }

    /**
    * Add drag events, remove transition and set initial X and Y offsets.
    * @param e
    */
    enableDrag(e) {
      e = e || window.event;
      e.cancelBubble = true;
      e.preventDefault();

      const zoomLevel = parseInt(this.image.dataset.zoomLevel);

      if (!zoomLevel) {
        return;
      }

      this.image.style.transition = '';
      this.image.style.WebkitTransition = '';
      app.element.removeClass('jZoom_transition-main', this.image);

      this.offsetX = e.pageX;
      this.offsetY = e.pageY;

      this.image.addEventListener('touchmove', this.dragImage);
      this.overlay.removeEventListener('click', this.toggle);
      this.image.addEventListener('mousemove', this.dragImage);
    }

    /**
     * Remove events and re-apply transition.
     * @param e
     * @param leave
     */
    disableDrag(e, leave) {
      this.image.style.transition = 'transform 0.5s ease';
      this.image.style.webkitTransition = 'transform 0.5s ease';
      app.element.addClass('jZoom_transition-main', this.image);

      if (siteObj.siteIsMobile) {
        this.image.removeEventListener('touchmove', this.dragImage);
      } else {
        this.slideBack();

        if (!leave) {
          this.overlay.addEventListener('click', this.toggle);
        }

        this.image.removeEventListener('mousemove', this.dragImage);
      }
    }

    /**
     * Differentiate between a pinch and a touch.
     * @param e
     */
    touchHandler(e) {
      // Add touchmove events to drag and enablePinchZoom?
      if (e.touches.length === 2) {
        this.enablePinchZoom(e);
      } else {
        this.enableDrag(e);
      }
    }

    /**
     * Handle end of touch input.
     * @param e
     */
    touchEndHandler(e) {
      this.disableDrag(e);
      this.disablePinchZoom(e);
    }

    /**
     * Get the distance between two touch points.
     * @param x1
     * @param y1
     * @param x2
     * @param y2
     * @returns {number}
     */
    touchDistance(x1, y1, x2, y2) {
      return Math.sqrt((x1 - x2) * (x1 - x2) + (y1 - y2) * (y1 - y2));
    }

    /**
     * Add pinch and remove drag event in case it's called.
     * @param e
     */
    enablePinchZoom(e) {
      e = e || window.event;
      e.cancelBubble = true;
      e.preventDefault();

      this.image.style.transition = '';
      this.image.style.webkitTransition = '';
      app.element.removeClass('jZoom_transition-main', this.image);
      this.lastTouchDistance = this.touchDistance(e.touches[0].clientX, e.touches[0].clientY, e.touches[1].clientX, e.touches[1].clientY);

      /*
      todo - this is for scaleAtPoint fn
      this.nx = ((e.touches[0].clientX + e.touches[1].clientX) / 2);
      this.ny = ((e.touches[0].clientY + e.touches[1].clientY) / 2);
      const gdd = this.getDynamicDimension(this.image);
      const opw = gdd.w / 100;
      const oph = gdd.h / 100;
      */

      this.image.removeEventListener('touchmove', this.dragImage);
      this.image.addEventListener('touchmove', this.pinchZoom);
    }

    /**
     * Remove pinch and drag events.
     */
    disablePinchZoom() {
      this.image.style.transition = 'transform 0.5s ease';
      this.image.style.webkitTransition = 'transform 0.5s ease';
      app.element.addClass('jZoom_transition-main', this.image);

      this.slideBack();

      this.image.removeEventListener('touchmove', this.dragImage);
      this.image.removeEventListener('touchmove', this.pinchZoom);
    }

    /**
     * Pinch an image to zoom in/out.
     * @param e
     */
    pinchZoom(e) {
      const distance = this.touchDistance(e.touches[0].clientX, e.touches[0].clientY, e.touches[1].clientX, e.touches[1].clientY);
      const increasing = distance > this.lastTouchDistance;

      let zoomLevel = parseInt(this.image.dataset.zoomLevel);

      if (increasing) {
        zoomLevel += 7; //zoomLevel > 62.5 ? zoomLevel += 4 : zoomLevel += 7;
      } else {
        zoomLevel -= 7; //zoomLevel > 62.5 ? zoomLevel -= 4 : zoomLevel -= 7;
      }

      if (zoomLevel <= 0) {
        zoomLevel = 0;
      } else if (zoomLevel >= 125) {
        zoomLevel = 125;
      }

      this.zoomDisabled(zoomLevel);

      this.scale = 1 + (zoomLevel * this.zoomPercentVal);
      this.image.style.transformOrigin = '50% 50%';
      this.image.style.webkitTransformOrigin = '50% 50%';
      this.image.style.transform = `matrix(${this.scale},0,0,${this.scale},${this.image.coordX},${this.image.coordY})`;
      this.image.style.webkitTransform = `matrix(${this.scale},0,0,${this.scale},${this.image.coordX},${this.image.coordY})`;
      this.lastTouchDistance = distance;
      this.image.dataset.zoomLevel = zoomLevel;
      this.image.style.transformOrigin = '';
      this.image.style.webskitTransformOrigin = '';

      this.slideBack();

      if (zoomLevel <= 0) {
        this.recentre();
      }
    }

    /**
     * Bounce the image when reached max zoom via pinch.
     */
    bounceStop() {
      let newScale = this.scale + 0.2;
      let i = 1;

      const bounce = () => {
        if (i >= 2) {
          this.image.style.transform = `matrix(${this.scale},0,0,${this.scale},${this.image.coordX},${this.image.coordY})`;
          this.image.style.webkitTransform = `matrix(${this.scale},0,0,${this.scale},${this.image.coordX},${this.image.coordY})`;

          this.image.style.transition = 'transform 0.5s ease';
          this.image.style.WebkitTransition = 'transform 0.5s ease';

          return;
        }

        this.image.style.transition = 'transform 0.25s';
        this.image.style.webkitTransition = 'transform 0.25s';
        app.element.addClass('jZoom_transition-short', this.image);

        this.image.style.transform = `matrix(${newScale},0,0,${newScale},${this.image.coordX},${this.image.coordY})`;
        this.image.style.webkitTransform = `matrix(${newScale},0,0,${newScale},${this.image.coordX},${this.image.coordY})`;

        i++;

        setTimeout(bounce, 250);
      };

      bounce();
    }

    /**
     * Detect portrait or landscape.
     */
    orient() {
      this.isPortrait = window.innerHeight > window.innerWidth;

      return this.isPortrait;
    }

    /**
     * Adjust jZoom container size.
     */
    adjustContainer() {
      if (this.isPortrait) {
        this.jZoom.style.width = `${window.innerWidth}px`;
        this.jZoom.style.height = `${window.innerHeight}px`;
        this.jZoom.style.left = '';
      } else {
        this.jZoom.style.width = `${window.innerHeight}px`;
        this.jZoom.style.height = `${window.innerHeight}px`;
        this.jZoom.style.left = `${window.innerWidth / 2 - window.innerHeight / 2}px`;
      }
    }

    /**
     * Resize overlay and image.
     */
    resize() {
      this.adjustContainer();

      this.overlay.style.width = `${window.innerWidth}px`;
      this.overlay.style.height = `${window.innerHeight}px`;
      this.image.style.height = `${window.innerHeight}px`;
    }

    /**
     * Disable the zoom in landscape mobile.
     */
    checkM() {

      if (siteObj.siteIsMobile && !this.isPortrait) {
        //app.element.addClass('jZoom-superhide', this.controls.openIcon);
        app.element.addClass('jZoom_overlay-superhide', this.overlay);
      } else {
        //app.element.removeClass('jZoom-superhide', this.controls.openIcon);
        app.element.removeClass('jZoom_overlay-superhide', this.overlay);
      }
    }

    /**
     * Return the computed style of a specific property of an element.
     * @param element
     * @param property
     * @returns {string}
     */
    getStyle(element, property) {
      return window.getComputedStyle(element, null).getPropertyValue(property);
    }

    /**
     * Return the height and width of an element taking into account transformations.
     * @param element
     * @returns {{
     *    w: Number,
     *    h: Number
     * }}
     */
    getDynamicDimension(element) {
      const bcr = element.getBoundingClientRect();

      return {
        w: bcr.width,
        h: bcr.height,
        left: bcr.left,
        top: bcr.top
      };
    }

    /**
     * Find the offset between an element and the edge of the screen.
     * @param element
     * @param edge
     * @returns {number}
     */
    findTotalOffset(element, edge, test) {
      let offset = 0;
      let elementOffset = test || parseInt(this.getStyle(this.jZoom, 'border-width').split('p')[0]);
      let direction;

      switch (edge) {
        case 'top':
          direction = 'offsetTop';
          break;

        case 'left':
          direction = 'offsetLeft';
          break;

        case 'bottom':
          direction = 'offsetTop';
          elementOffset += parseInt(this.getStyle(this.jZoom, 'height').split('p')[0]);
          break;

        case 'right':
          direction = 'offsetLeft';
          elementOffset += parseInt(this.getStyle(this.jZoom, 'width').split('p')[0]);
          break;
      }

      while (element.offsetParent) {
        if (!isNaN(element[direction])) {
          offset += element[direction];
        }
        element = element.offsetParent;
      }

      return offset + elementOffset;
    }

    /**
     * todo - when image variations are being done for other sites
     * Scale an image around a focal point
     * @param x
     * @param y
     * @param scale
     */
    scaleAtPoint(x, y) {
      const rect = this.image.getBoundingClientRect();

      // find cursor offset within the element
      x -= rect.left;
      y -= rect.top;

      // find the final position of the coordinate after scaling
      const xf = x * this.scale;
      const yf = y * this.scale;

      // find the difference between the initial and final position
      // and add the difference to the current position
      const dx = this.image.coordX + x - xf;
      const dy = this.image.coordY + y - yf;

      // apply the transform
      this.image.style.transform = `matrix(${this.scale},0,0,${this.scale},${dx},${dy})`;
      this.image.style.webkitTransform = `matrix(${this.scale},0,0,${this.scale},${dx},${dy})`;
    }
  }

  // Only execute if we aren't using PhantomJS
  // If testing jZoom is called separately inside the spec after the DOM has been prepped
  if (window.navigator.userAgent.indexOf('Phantom') < 0) {
    new jZoom();

    if (siteObj.siteIsMobile) {
      new jThumbsM();
    } else {
      new jThumbs();
    }
  }

  return jZoom;
});
